function validateEmail(eventType,textBoxPrefix) {
	$('#'+textBoxPrefix+'email').css({'border':'unset','background-color':'unset'});
	var email = $.trim($('#'+textBoxPrefix+'email').val());
	$('#'+textBoxPrefix+'email').val(email);

	var reg = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	if( false == reg.test(email) ){
		$('#'+textBoxPrefix+'email').css({"border":"2px solid #f99595","background-color":"#f2b8b8"});
		$('#'+textBoxPrefix+'email').focus();
		// startup_toastMe('Please enter valid email id', 1);
		$('#notifications').empty();
		Notify("Please enter valid email address", null, null, 'danger');
		eventType.preventDefault();
		return false;
	} else {
		if (eventType.target.id == 'registerForm') {
			if ($("#register_password").val() != $("#password_confirmation").val()) {
				$('#password_confirmation').css({"border":"2px solid #f99595","background-color":"#f2b8b8"});
				$('#password_confirmation').focus();
				// startup_toastMe('Confirm password do not match', 1);
				$('#notifications').empty();
				Notify("Confirm password do not match", null, null, 'danger');
				eventType.preventDefault();
				return false;
			}
		}
		return true;
	}
}

function validateConfirmPassword(eventType) {
	if ($("#password").val() == '') {
        $('#password').css({"border":"2px solid #f99595","background-color":"#f2b8b8"});
        $('#password').focus();
        $('#notifications').empty();
        Notify("Old password is required", null, null, 'danger');
        eventType.preventDefault();
        return false;
	} else if ($("#new_password").val() == '') {
        $('#password').css({"border":"none","border-bottom":"1px solid #bcbcbc", "background-color":"#fff"});
        $('#new_password').css({"border":"2px solid #f99595","background-color":"#f2b8b8"});
        $('#new_password').focus();
        $('#notifications').empty();
        Notify("New password is required", null, null, 'danger');
        eventType.preventDefault();
        return false;
	} else if ($("#new_password").val() != $("#new_password_confirmation").val()) {
        $('#password').css({"border":"none","border-bottom":"1px solid #bcbcbc", "background-color":"#fff"});
        $('#new_password').css({"border":"none","border-bottom":"1px solid #bcbcbc", "background-color":"#fff"});
		$('#new_password_confirmation').css({"border":"2px solid #f99595","background-color":"#f2b8b8"});
		$('#new_password_confirmation').focus();
		// startup_toastMe('Confirm password do not match', 1);
		$('#notifications').empty();
		Notify("Confirm password do not match", null, null, 'danger');
		eventType.preventDefault();
		return false;
	} else {
		return true;
	}
}

$('#Startup_videoPlayerModel').on('hidden.bs.modal', function () {
   $('#startup_videoPlayer').attr('src','');
});

