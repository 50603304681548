$(document).ready(function() {
    $.ajaxSetup({ cache: true });

    $.getScript("https://platform.linkedin.com/in.js?async=true", function success() {
        IN.init({
            api_key:"81b64kzfi68c8f",
            authorize: true
        });
    });

    $.getScript('//connect.facebook.net/en_US/sdk.js', function(){
        FB.init({
            appId: '214785498984639',
            version: 'v2.8' // or v2.1, v2.2, v2.3, ...
        });
        $('.fbLogin').click(function () {
            FB.login(function (response) {
                if (response.authResponse) {
                    FB.api('/me', {"fields": "id,email,birthday,first_name,last_name,gender"}, function (detailsResponse) {
                        if (typeof detailsResponse.email === "undefined") {
                            //error
                        } else {
                            var data = {
                                email: detailsResponse.email,
                                first_name: detailsResponse.first_name,
                                last_name: detailsResponse.last_name,
                                accessToken: response.authResponse.accessToken,
                                type: 'FB',
                                accountUserId: detailsResponse.id
                            };

                            doLogin(data);
                        }
                    });
                } else {
                    //error
                }
            }, {scope: 'email,public_profile', auth_type: 'rerequest'});
        });
        
    });

    $.getScript("https://apis.google.com/js/platform.js", function() {
        gapi.load('auth2', function() {
            //load in the auth2 api's, without it gapi.auth2 will be undefined
            gapi.auth2.init(
                {
                    client_id: '1014177684932-tgemej12n0n1oubt619okrjn6pdju15f.apps.googleusercontent.com'
                }
            );
            var GoogleAuth  = gapi.auth2.getAuthInstance();//get's a GoogleAuth instance with your client-id, needs to be called after gapi.auth2.init
            $(".gLogin").click(function(){//add a function to the controller so ng-click can bind to it
                GoogleAuth.signIn().then(function(response){//request to sign in
                    var profile = GoogleAuth.currentUser.get().getBasicProfile();

                    var data = {
                        email: profile.getEmail(),
                        first_name: profile.getGivenName(),
                        last_name: profile.getFamilyName(),
                        type: 'GOOGLE',
                        accountUserId: profile.getId()
                    };

                    doLogin(data);
                });
            });
        });
    });

    $(".linkedInLogin").click(function () {
        IN.UI.Authorize().place();
        IN.Event.on(IN, "auth", function () {
            IN.API.Profile("me").fields("id", "first-name", "last-name", "email-address").result(function (data) {
                var profile = data.values[0];
                var data = {
                    email: profile.emailAddress,
                    first_name: profile.firstName,
                    last_name: profile.lastName,
                    type: 'GOOGLE',
                    accountUserId: profile.id
                };

                doLogin(data);
            }).error(function (data) {
                console.log(data);
            });
        });
        IN.Event.on(IN, "logout", function () { onLogout(); });
    });
    
    $(".twitterLogin").click(function () {
        window.location.href = 'auth/twitter';
    });
});

function doLogin(data) {
    var header = {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    };

    $.ajax({
        type: 'POST',
        url: '/social/handle',
        data: data,
        headers: header,
        success: function (data) {
            if (data.success) {
                window.location.href = '/home';
            }
        }
    });
}
