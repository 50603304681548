var videoNext = {
    "topPage": 2,
    "recentPage": 2,
    "favoritePage": 2,
    "likedPage": 2,
    "searchQuery": null
};
!function (t) {
    "use strict";
    t("a.page-scroll").bind("click", function (a) {
        var o = t(this);
        t("html, body").stop().animate({
            scrollTop: t(o.attr("href")).offset().top - 48
        }, 1250, "easeInOutExpo"), a.preventDefault();
    }), t("body").scrollspy({
        target: ".navbar-fixed-top",
        offset: 51
    }), t(".navbar-collapse ul li .page-scroll").click(function () {
        //t(".navbar-toggle:visible").click();
    }), t("#mainNav").affix({
        offset: {
            top: 200
        }
    });
}(jQuery);
var startup_headerFlag = 0;
var startup_isiPad = navigator.userAgent.match(/iPad/i) != null;
var startup_isMobile = navigator.userAgent.match(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i) != null;
var startup_DB_total_recently_added;
var startup_DB_total_liked;
var startup_DB_total_favorited;
var startup_DB_total_top10;
$(document).ready(function () {
    
    
    if (startup_isiPad || startup_isMobile)
    {
        $('.startup_hoverIframe').css('display', 'none');
    }
    $(document).click(function (event) {
        var clickover = $(event.target);
        var _opened = $(".navbar-collapse").hasClass("in");
        if (_opened === true) {
            $("button.navbar-toggle").click();
        }
    });
    /* check divice flip */
    var supportsOrientationChange = "onorientationchange" in window,
            orientationEvent = supportsOrientationChange ? "orientationchange" : "resize";

    window.addEventListener(orientationEvent, function () {
        startup_reInitSliderOnMobile();
    }, false);
    startup_DB_total_recently_added = $('#recently-added').data("field-id");
    startup_DB_total_liked = $('#liked-episodes').data("field-id");
    startup_DB_total_favorited = $('#favorited-episodes').data("field-id");
    startup_DB_total_top10 = $('#top-videos').find('.startup_sliderList li').length;
    $('.startup_FormGlobalTextBox').focus(function () {
        $(this).closest('.form-group').find('label').addClass('startup_activeTextBox');
        $(this).closest('.form-group').find('.startup_calenderIconProfile').addClass('startup_activeTextBoxBorder');
    });
    $('.startup_FormGlobalTextBox').blur(function () {
        $(this).closest('.form-group').find('label').removeClass('startup_activeTextBox');
        $(this).closest('.form-group').find('.startup_calenderIconProfile').removeClass('startup_activeTextBoxBorder');
    });
    $('.startup_videoSliderContainer').append('<div class="swiper-button-next"></div><div class="swiper-button-prev"></div>');
    var swiper = new Swiper('.startup_videoSliderContainer', {
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        slidesPerView: 3,
        breakpoints: {
            991: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 1,
            }
        }
    });
    startup_setEqualHeight();
});

var startup_resizeId;
$(window).resize(function () {
    clearTimeout(startup_resizeId);
    startup_resizeId = setTimeout(function () {
        if (!(startup_isMobile))
        {
            startup_setEqualHeight();
            startup_setHeightForIframe();
        }
        startup_setHeightOfHeader();
        startup_reInitSlider();
    }, 100);
});
function startup_setHeightOfHeader()
{
    $('.startup_commonHeader').css('height', $(window).height());
}
function startup_setEqualHeight()
{
    if (!(startup_isMobile))
    {
        var startup_maxHeight = 0;
        $('.startup_matchHeight').css('height', '');
        $('.startup_matchHeightParent').each(function () {
            $(this).find('.startup_matchHeight').each(function () {
                if (startup_maxHeight <= $(this).height())
                {
                    startup_maxHeight = $(this).height();
                }
            });
            $('.startup_matchHeight').height(startup_maxHeight);
            startup_maxHeight = 0;
        });
    }
}

function startup_reInitSlider()
{
    $('.startup_sliderList').each(function (index, el) {
        if (startup_isMobile)
        {
            startup_currentSliderNode = 1;
            startup_gapInSlides = 2;
        }
        $(this).find('.startup_sliderListItem').removeClass('startup_LastElement');

        startup_totalSliderNode = $(this).find('li').length;
        $(this).find('li:nth-child(' + startup_totalSliderNode + ')').addClass('startup_LastElement');

        startup_slideWidth = $('.startup_sliderList').width() / startup_currentSliderNode;
        $(this).find('li').css('width', (startup_slideWidth - startup_gapInSlides - 1) + 'px');
        startup_slideAnimationAmount = (startup_slideWidth - startup_gapInSlides + 3);

        if (startup_isMobile)
        {
            $(this).find('li').css('width', (startup_slideWidth) + 'px');
            startup_slideAnimationAmount = (startup_slideWidth);
        }
    });
}

function startup_setHeightForIframe()
{
    $('.startup_customVideoOverlayInner iframe').height($(window).height() - 80);
}
function startup_reInitSliderOnMobile()
{
}



$(document).ready(function () {
    $('.startup_smallMenuButton').click(function(){
        if($('#mainNav').hasClass('menuOpen')){
            setTimeout(function(){
                $('#mainNav').removeClass('menuOpen');
            }, 300);
        }
        else{
            $('#mainNav').addClass('menuOpen');
        }        
    })
    startup_setHeightOfHeader();
    if (!(startup_isMobile))
    {
        startup_setHeightForIframe();
    }
    $('.startup_calenderIconProfile').click(function () {
        $("#startup_DOBDatepicker").datepicker("show");
    });
    $('#startup_DOBDatepicker').datepicker({
        changeMonth: true,
        changeYear: true,
        dateFormat: " d MM, yy",
        maxDate: "+0D",
        yearRange: "-100Y:maxDate"
    });

    $('.startup_scrolToTop').click(function () {
        $('html,body').animate({scrollTop: 0}, 500);
    });
    $('.startup_profileCheckBox').click(function () {
        if ($(this).hasClass('startup_profileCheckBoxClicked'))
        {
            $(this).removeClass('startup_profileCheckBoxClicked');
            $(this).html('');
            $('#is_subscribe').attr('value', '0');
        } else
        {
            $(this).addClass('startup_profileCheckBoxClicked');
            $(this).html('<span class="glyphicon glyphicon-ok" aria-hidden="true"></span>');
            $('#is_subscribe').attr('value', '1');
        }
    });
    $('.startup_sliderList').each(function (index, el) {
        startup_totalSliderNode = $(this).find('li').length;
        if (startup_isMobile && 2 > startup_totalSliderNode) {
            return;
        }
        if (7 > startup_totalSliderNode) {
            return;
        }
        if (startup_isMobile)
        {
            startup_currentSliderNode = 1;
            startup_gapInSlides = 2;
        }
        $(this).find('li:nth-child(1)').addClass('startup_currentlyFirst');
        $(this).find('li:nth-child(' + startup_currentSliderNode + ')').addClass('startup_currentlyLast');
        $(this).find('li:nth-child(' + startup_totalSliderNode + ')').addClass('startup_LastElement');
        startup_slideWidth = $('.startup_sliderList').width() / startup_currentSliderNode;
        $(this).find('li').css('width', (startup_slideWidth - startup_gapInSlides - 1) + 'px');
        startup_slideAnimationAmount = (startup_slideWidth + startup_gapInSlides + 3);
        $(this).attr("data-animation-value", 0);
        $(this).closest('.startup_sliderListMain').append('<div class="startup_sliderListNext startup_sliderListNavigator"><span class="glyphicon glyphicon-menu-right"></span></div><div class="startup_sliderListPrev startup_sliderListNavigator"><span class="glyphicon glyphicon-menu-left"></span></div>');
        var temp_lock = true;
        $('.startup_sliderListNext').unbind().click(function (e) {
            e.stopPropagation();
            e.preventDefault();
            if (temp_lock == true)
            {
                temp_lock = false;
                startup_scrollToLeft($(this).closest('.startup_sliderListMain').find('.startup_sliderList'));
                setTimeout(function () {
                    temp_lock = true;
                }, 500);
            }
        });
        $('.startup_sliderListPrev').unbind().click(function (e) {
            e.stopPropagation();
            if (temp_lock)
            {
                temp_lock = false;
                startup_scrollToRight($(this).closest('.startup_sliderListMain').find('.startup_sliderList'));
                setTimeout(function () {
                    temp_lock = true;
                }, 500);
            }
        });
        $('.startup_sliderListNavigator').click(function () {
            setTimeout(function () {
                startup_checkForBothEndLimit();
            }, 700);
        });
    });

    // Hidden video section Close Button Functionlity 

    $('.startup_closeDetailVideo').click(function () {
        $(this).closest('.startup_mainVideoBlockLevel').find('.startup_videosInfo').slideUp();
        $(this).closest('.startup_mainVideoBlockLevel .startup_sliderListSearchPage').find('.startup_videosInfo').slideUp(250);
        $('.startup_sliderListItem').removeClass('startup_marginBottom550');
        $(this).closest('.startup_mainVideoBlockLevel').find('.hiddenVideosMainBox').each(function () {
            $(this).attr('data-active-flag', '0');
            $(this).find('.hiddenVideosMainBoxDetailsInActive').removeClass('startup_hideMe');
            $(this).find('.hiddenVideosMainBoxDetailsActive').addClass('startup_hideMe');
            $(this).find('.startup_smallVideoPlayButton').removeClass('startup_hideMe');
        });
        setTimeout(function () {
            $('.startup_sliderListItem').css('margin-bottom', '');
        }, 200);
    });
});

var startup_slideWidth;
var startup_slideAnimationAmount;
var startup_scrollStart;
var startup_scrollEnd;
var startup_scrollAmount = 100;
var startup_currentSliderNode = 6;
var startup_totalSliderNode;
var startup_sliderAnimation = true;
var startup_gapInSlides = 3;
var startup_slideCurrentAnimationValue = 0;

$(window).scroll(function () {
    if ($(window).scrollTop() > 600) {
        $('.startup_scrolToTop').fadeIn();
    } else {
        $('.startup_scrolToTop').fadeOut();
    }
});

var startup_animationLockSearchPage = true;
var url_path;
var url;
var current_url;
url_path = window.location.pathname; // Returns path only
url = window.location.href;     // Returns full URL
current_url = url_path;
var isSearchPageString = current_url;
var isSearchPage = isSearchPageString.indexOf("search");


function startup_checkHeightOfSearchPageVideoArea()
{
    if ($('.startup_sliderListSearchPageResult').height() >= 550)
    {
        $('.startup_sliderListSearchPage ul.startup_sliderList .startup_marginBottom550').css('margin-bottom', $('.startup_sliderListSearchPageResult').height() + 50 + 'px');
    } else
    {
        $('.startup_sliderListSearchPage ul.startup_sliderList .startup_marginBottom550').css('margin-bottom', '');
    }
}

$(window).load(function () {
    
    if((!(window.ActiveXObject) && "ActiveXObject" in window))
    {
        
    }
    
    
    wow = new WOW(
        {
            animateClass: 'animated',
            offset: 100,
            callback: function (box) {
                // console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
            }
        }
    );
    wow.init();
    startup_reInitSlider();
    $('.startup_hoverIframe').each(function (index) {
        $(this).attr('id', 'startup_hoverIframe' + index + 1);
    });
    $('.startup_hoverIframe').click(function () {
        $(this).closest('.startup_videosInner').find('.startup_VideoPlayButton').click();
    });
    $(document).on({
        mouseenter: function () {
            //console.log($(this).find('.startup_hoverIframe').mouseover());
            $(this).find('.startup_videosSectionBoxInner').css('pointer-events', 'none');
        },
        mouseleave: function () {
            $(this).find('.startup_videosSectionBoxInner').css('pointer-events', '');
        }
    }, '.startup_videosInner');
    var player;
    var $$ = function (tagname) {
        return document.getElementsByClassName(tagname);
    };
    function startup_hoverVideoIframePlay() {
        var videos = $$('startup_hoverIframe'), // the iframes elements
                players = [], // an array where we stock each videos youtube instances class
                playingID = null; // stock the current playing video

        for (var i = 0; i < videos.length; i++) // for each iframes
        {
            var currentIframeID = videos[i].id; // we get the iframe ID
            players[currentIframeID] = new YT.Player(currentIframeID); // we stock in the array the instance
            // note, the key of each array element will be the iframe ID
            videos[i].onmouseover = function (e) { // assigning a callback for this event
                var currentHoveredElement = e.target;
                if (playingID) // if a video is currently played
                {
                    players[playingID].pauseVideo();
                }
                players[currentHoveredElement.id].playVideo();
                playingID = currentHoveredElement.id;
            };
            videos[i].onmouseleave = function (e) { // assigning a callback for this event
                var currentHoveredElement = e.target;
                playingID = currentHoveredElement.id;

                if (playingID) // if a video is currently played
                {
                    players[playingID].pauseVideo();
                }
            };
        }
    }
    startup_hoverVideoIframePlay();
    $('.startup_sliderListItem').each(function (index, el) {
        if ($(window).width() < 768)
        {
            $(this).addClass('startup_sliderListItemGroup' + parseInt((index / 1 + 1), 10));
            $(this).attr('data-group-id', parseInt((index / 1 + 1), 10));
        } else
        {
            $(this).addClass('startup_sliderListItemGroup' + parseInt((index / 5 + 1), 10));
            $(this).attr('data-group-id', parseInt((index / 5 + 1), 10));
        }

    });
    $('.startup_sliderListSearchPageResult').css('max-width', $('.startup_sliderListMain').width() - 20);
    //startup_checkForBothEndLimit();
    //startup_reInitSliderOnMobile();
    if (startup_isMobile)
    {
        $('.startup_formButtonGlobal').html('<span class="glyphicon glyphicon-arrow-right"  aria-hidden="true"></span>');
    }

    /* 
     flag values.
     
     set 0 for background images (only 1 image)
     set 1 from video background
     set 2 for carousal (multiple images)
     
     bannerType variable set in header
     */

    startup_headerFlag = (typeof bannerType !== 'undefined') ? bannerType : -1;
    if (startup_headerFlag == 1)
    {
        $('.startup_headerBackgroundVideoWrapper').removeClass('startup_hideMe');
        $('.startup_headCarousalWrapper').addClass('startup_hideMe');
    } else if (startup_headerFlag == 2)
    {
        $('.startup_headerBackgroundVideoWrapper').addClass('startup_hideMe');
        $('.startup_headCarousalWrapper').removeClass('startup_hideMe');
    } else
    {
        $('.startup_headerBackgroundVideoWrapper').addClass('startup_hideMe');
        $('.startup_headCarousalWrapper').addClass('startup_hideMe');
    }

    if (startup_headerFlag == 2)
    {
        $('.startup_commonHeader').addClass('startup_hideMe');
    } else
    {
        $('.startup_commonHeader').removeClass('startup_hideMe');
    }

    $('.carousel').carousel();
    // left right moving of video list
    $('.startup_videoListMoveBack span').click(function () {
        $(this).closest('.startup_mainVideoBlockLevel').find('ul.startup_upcomingVideosList').animate({scrollLeft: $(this).closest('.startup_mainVideoBlockLevel').find('ul.startup_upcomingVideosList').scrollLeft() - 200}, 200);
    });

    $('.startup_videoListMoveForward span').click(function () {
        $(this).closest('.startup_mainVideoBlockLevel').find('ul.startup_upcomingVideosList').animate({scrollLeft: $(this).closest('.startup_mainVideoBlockLevel').find('ul.startup_upcomingVideosList').scrollLeft() + 200}, 200);
    });

    var startup_animationDelay = 500, startup_setTimeoutConst;
    $(document).on({
        mouseenter: function () {
            var currentMouseEnter = $(this);
            if ($(this).closest('.startup_sliderListItem').hasClass('startup_sliderLastLiElement'))
            {
                return false;
            }
            startup_setTimeoutConst = setTimeout(function () {
                $(currentMouseEnter).click();
            }, startup_animationDelay);
        },
        mouseleave: function () {
            clearTimeout(startup_setTimeoutConst);
        }
    }, '.hiddenVideosMainBox');

    $(document.body).on('click', '.hiddenVideosMainBox', function (e) {
        if ($(this).closest('.startup_sliderListItem').hasClass('startup_sliderLastLiElement'))
        {
            return true;
        }
        if (e.originalEvent != undefined)
        {
            return false;
        }
        var currentAnimation = $(this);
        var currentAnimationLi = $(this).closest('.startup_sliderListItem');
        if ($(window).width() < 768){
            var currentLiHight = currentAnimationLi.height() + 20;
        }      
        else{
            var currentLiHight = 0;
        }
        setTimeout(function () {
            //$('html,body').animate({scrollTop: ($(currentAnimation).offset().top - 60)}, 400);
        }, 500);
        var isGuest = $('[name="is-guest"]').attr('content');
        $('.startup_videosInfoInner').removeClass('hide');
        if (isSearchPage == 1)
        {
            /* code for search page */
            $('.startup_sliderListItem').css('margin-bottom', '');
            if ($('.startup_sliderListItem').hasClass('startup_marginBottom550'))
            {
                $(currentAnimation).closest('.startup_mainVideoBlockLevel').find('.startup_videosInfo').slideDown(500, function () {
                    //console.log($(currentAnimationLi).data('group-id'))
                    if (startup_animationLockSearchPage)
                    {
                        startup_animationLockSearchPage = false;
                        if ($('.startup_sliderListItemGroup' + $(currentAnimationLi).data('group-id')).hasClass('startup_marginBottom550'))
                        {

                        } else
                        {
                            $('.startup_sliderListItem').removeClass('startup_marginBottom550');
                            $('.startup_sliderListItemGroup' + $(currentAnimationLi).data('group-id')).addClass('startup_marginBottom550');
                        }
                        $('.startup_sliderListSearchPageResult').css('top', $(currentAnimation).offset().top + $(currentAnimation).height());
                        setTimeout(function () {
                            startup_animationLockSearchPage = true;
                        }, 20);
                    }
                    $('html,body').animate({scrollTop: ($(currentAnimation).offset().top - 60 + currentLiHight)}, 400, function () { });
                    setTimeout(function () {
                        startup_checkHeightOfSearchPageVideoArea();
                    }, 200);
                });
            } else
            {
                if (startup_animationLockSearchPage)
                {
                    startup_animationLockSearchPage = false;
                    if ($('.startup_sliderListItemGroup' + $(currentAnimationLi).data('group-id')).hasClass('startup_marginBottom550'))
                    {

                    } else
                    {
                        $('.startup_sliderListItem').removeClass('startup_marginBottom550');
                        $('.startup_sliderListItemGroup' + $(currentAnimationLi).data('group-id')).addClass('startup_marginBottom550');
                    }
                    $('.startup_sliderListSearchPageResult').css('top', $(currentAnimation).offset().top + $(currentAnimation).height());
                    setTimeout(function () {
                        startup_animationLockSearchPage = true;
                    }, 20);
                }
                $(currentAnimation).closest('.startup_mainVideoBlockLevel').find('.startup_videosInfo').slideDown(500, function () {
                    $('html,body').animate({scrollTop: ($(currentAnimation).offset().top - 60 + currentLiHight)}, 400, function () { });
                    setTimeout(function () {
                        startup_checkHeightOfSearchPageVideoArea();
                    }, 200);
                });
            }

        } else
        {
            $(this).closest('.startup_mainVideoBlockLevel').find('.startup_videosInfo').slideDown(500, function () {
                $('html,body').animate({scrollTop: ($(currentAnimation).offset().top - 60 + currentLiHight)}, 400);
            });
        }

        var startup_tempSelf = $(this);
        var startup_tempSelfMainWrapper = $(this).closest('.startup_mainVideoBlockLevel')[0];
        $(this).closest('.startup_mainVideoBlockLevel').find('.startup_VideoPlayButton').attr('data-video-to-play', $(this).attr('data-current-video'));
        $('.startup_videosInfoInner').show();
        $('.startup_closeDetailVideo').show();
        var episodeId = $(this).attr('episode-id');
        var header = {
            "Content-Type": 'application/json'
        };
        $.ajax({
            url: '/episodes/episode-details/' + episodeId,
            headers: header,
            type: "GET",
            contentType: false,
            processData: false
        }).done(function (data) {
            $('.total-share').text('Share :');
            var likeLogoSrc, favLogoSrc;
            if (data.details.likeStatus) {
                likeLogoSrc = '/assets/images/like-pressed.png';
            } else {
                likeLogoSrc = '/assets/images/like-normal.png';
            }

            if (data.details.favoriteStatus) {
                favLogoSrc = '/assets/images/fav-pressed.png';
            } else {
                favLogoSrc = '/assets/images/fav-normal.png';
            }

            var episodeDuration = data.details.duration + ' Mins';
            if ('00:59' >= data.details.duration) {
                episodeDuration = data.details.duration + ' Min';
            }
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_videosInfoOfCurrentSelectedTitleH3 span').html(data.details.name);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_videosInfoOfCurrentSelectedDuration').html(episodeDuration);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_videosInfoOfCurrentSelectedTime').html(data.details.created);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_currentSelectedInfoDetail').html('<div class="comment">' + data.details.description + '</div>');
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_currentSelectedInfoStartName span').text(data.details.startup_name);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_currentSelectedInfoFounderName span').text(data.details.founder_name);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_VideoPlayButtonCaption').html('PLAY : ' + data.details.name);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_currentSelectedInfoLikes').attr('episode-id', data.details.id);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_currentSelectedInfoLikes').attr('status', data.details.likeStatus);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_currentSelectedInfoAddFav').attr('episode-id', data.details.id);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_currentSelectedInfoAddFav').attr('status', data.details.favoriteStatus);
            if (data.details.audioLink) {
                $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_audioPlayer').html('<iframe width="80%" height="120" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=' + data.details.audioLink + '&amp;color=ff5500&amp;inverse=true&amp;auto_play=false&amp;show_user=true"></iframe>');
            }
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find(' .startup_playVideoContainer .startup_VideoPlayButton').attr('episode-id', data.details.id);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.fb-share').attr('href', 'https://www.facebook.com/sharer/sharer.php?u=' + data.details.video_link + '&amp;src=sdkpreparse');
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.twitter-share').attr('href', 'http://twitter.com/share?url=' + data.details.video_link);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.gplus-share').attr('href', 'https://plus.google.com/share?url=' + data.details.video_link);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.linkedin-share').attr('href', 'https://www.linkedin.com/shareArticle?mini=true&url=' + data.details.video_link);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.like-count').text(data.details.likeCount);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.like-count').addClass('like_count_' + episodeId);
            // $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find(".comment").shorten();
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.like-logo').attr({'src': likeLogoSrc, 'class': 'like-logo'});
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.favorite-logo').attr({'src': favLogoSrc, 'class': 'favorite-logo'});
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.like-logo').addClass('like_' + episodeId);
            $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.favorite-logo').addClass('favorite_' + episodeId);

            /**
             * Get Social Count 
             */
            $.ajax({
                url: '/episodes/get-social-count/' + episodeId,
                headers: header,
                type: "GET",
                contentType: false,
                processData: false
            }).done(function (shareCount) {
                console.log('Share Count' + shareCount);
                var totalShare = shareCount.details.facebookShareCount + shareCount.details.googlePlusShareCount + shareCount.details.linkedInShareCount + shareCount.details.twitterShareCount;
                $('.total-share').text('Shared :(' + totalShare + ')');
            });

        });
        $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_currentSelectedInfoLikes').unbind("click");
        $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_currentSelectedInfoLikes').click(function () {
            if (isGuest) {
                // startup_toastMe('You are not Logged-in, Please login', 1);
                $('#notifications').empty();
                Notify("You are not logged-in, please login", null, null, 'danger');
                return;
            }
            var episode_id = $(this).attr('episode-id');
            if ($(this).attr('status') == 1) {
                var is_active = 0;
                var logoSrc = '/assets/images/like-normal.png';
                $(' #liked-videos .li_' + episode_id).remove();
                window.dispatchEvent(new Event('resize'));
            } else {
                var is_active = 1;
                var logoSrc = '/assets/images/like-pressed.png';
                $(' #liked-videos .startup_upcomingVideosList').append($('.li_' + episode_id).clone());
                window.dispatchEvent(new Event('resize'));
            }
            $(' .liked_videoInfo .startup_closeDetailVideo').click();
            startup_reInitSlider();
            $.ajax({
                url: '/episodes/like/' + episode_id + '/' + is_active,
                headers: header,
                type: "GET",
                contentType: false,
                processData: false,
                success: function (data) {
                    $('.like_count_' + episode_id).text(data);
                    $('.startup_currentSelectedInfoLikes').each(function () {
                        if ($(this).attr('episode-id') == episode_id) {
                            $(this).attr('status', is_active);
                        }
                    });
                    $('.like_' + episode_id).attr('src', logoSrc);
                    countOfVideos = $('#liked-videos .startup_upcomingVideosList .swiper-slide').length;
                    if(countOfVideos== 0){
                         $('#liked-videos').html('No episodes found');
                    }
                }
            });
        });
        $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_currentSelectedInfoAddFav').unbind("click");
        $(startup_tempSelf).closest('.startup_mainVideoBlockLevel').find('.startup_currentSelectedInfoAddFav').click(function () {
            if (isGuest) {
                // startup_toastMe('You are not Logged-in, Please login', 1);
                $('#notifications').empty();
                Notify("You are not logged-in, please login", null, null, 'danger');
                return;
            }
            var episode_id = $(this).attr('episode-id');
            if ($(this).attr('status') == 1) {
                var is_active = 0;
                var logoSrc = '/assets/images/fav-normal.png';
                $(' #favorite-videos .li_' + episode_id).remove();
                window.dispatchEvent(new Event('resize'));
            } else {
                var is_active = 1;
                var logoSrc = '/assets/images/fav-pressed.png';
                $(' #favorite-videos .startup_upcomingVideosList').append($('.li_' + episode_id).clone());
                window.dispatchEvent(new Event('resize'));
            }
            $(' .favorite_videoInfo .startup_closeDetailVideo').click();
            startup_reInitSlider();
            $.ajax({
                url: '/episodes/favorite/' + episode_id + '/' + is_active,
                headers: header,
                type: "GET",
                contentType: false,
                processData: false,
                success: function (data) {
                    $('.startup_currentSelectedInfoAddFav').each(function () {
                        if ($(this).attr('episode-id') == episode_id) {
                            $(this).attr('status', is_active);
                        }
                    });
                    $('.favorite_' + episode_id).attr('src', logoSrc);
                    countOfVideos = $('#favorite-videos .startup_upcomingVideosList .swiper-slide').length;
                    if(countOfVideos== 0){
                         $('#favorite-videos').html('No episodes found');
                    }
                }
            });
        });
        if ($(this).attr('data-active-flag') == '1')
        {
            //inactive current flag
            $(this).attr('data-active-flag', '0');
            $(this).find('.hiddenVideosMainBoxDetailsInActive').removeClass('startup_hideMe');
            $(this).find('.hiddenVideosMainBoxDetailsActive').addClass('startup_hideMe');
            $(this).find('.startup_smallVideoPlayButton').removeClass('startup_hideMe');
        } else
        {
            $(this).closest('.hiddenVideoList').find('.hiddenVideosMainBox').each(function (index, element) {
                $(element).find('.hiddenVideosMainBoxDetailsInActive').removeClass('startup_hideMe');
                $(element).find('.hiddenVideosMainBoxDetailsActive').addClass('startup_hideMe');
                $(element).attr('data-active-flag', '0');
                $(element).find('.startup_smallVideoPlayButton').removeClass('startup_hideMe');
            });
            $(this).find('.startup_smallVideoPlayButton').addClass('startup_hideMe');
            $(this).find('.hiddenVideosMainBoxDetailsInActive').addClass('startup_hideMe');
            $(this).find('.hiddenVideosMainBoxDetailsActive').removeClass('startup_hideMe');
            $(this).attr('data-active-flag', '1');
        }
        startup_setMainVideoWindowValues($(this));
        if (startup_checkForAllInActive($(this)))
        {
            $(this).closest('.startup_mainVideoBlockLevel').find('.startup_videosInfo').slideUp();
            $(this).closest('.startup_mainVideoBlockLevel .startup_sliderListSearchPage').find('.startup_videosInfo').slideUp(250);
            $('.startup_sliderListItem').removeClass('startup_marginBottom550');
        }
        $('.startup_mainVideoBlockLevel').each(function (index, el) {
            if ($(el)[0] != $(startup_tempSelfMainWrapper)[0])
            {
                $(el).closest('.startup_mainVideoBlockLevel').find('.startup_videosInfo').slideUp();

                $(el).closest('.startup_mainVideoBlockLevel').find('.hiddenVideosMainBox').each(function () {
                    if ($(this).attr('data-active-flag') == '1')
                    {
                        //inactive current flag
                        $(this).attr('data-active-flag', '0');
                        $(this).find('.hiddenVideosMainBoxDetailsInActive').removeClass('startup_hideMe');
                        $(this).find('.hiddenVideosMainBoxDetailsActive').addClass('startup_hideMe');
                        $(this).find('.startup_smallVideoPlayButton').removeClass('startup_hideMe');
                    }
                });
            }
        });
    });

    if ($(window).width() < 768){
        $('.startup_closeDetailVideo').click(function () {
            var newOffsettop = $(this).closest('.startup_mainVideoBlockLevel').find('.swiper-container').offset().top;
            $('html,body').animate({scrollTop: (newOffsettop-55)});
        });
    }

    $('.startup_howItworksVideoPopUp').click(function () {
        var startup_newVideo = $(this).attr('data-video-to-play') + '?autoplay=1';
        $('#startup_videoPlayer').attr('src', startup_newVideo);
        $('#Startup_videoPlayerModel').modal('show');
    });
    $('.startup_videosSectionLargeBox , .hiddenVideoBlockPlayButton ').click(function () {
        var episode_id = $(this).find('.startup_VideoPlayButton').attr('episode-id');
        var header = {
            "Content-Type": 'application/json'
        };

        $.ajax({
            url: '/episodes/view/' + episode_id,
            headers: header,
            type: "GET",
            contentType: false,
            processData: false,
            success: function (data) {
                // console.log(data);
            }
        });

        var startup_newVideo = $(this).find('.startup_VideoPlayButton').attr('data-video-to-play') + '?autoplay=1';
        //console.log(startup_newVideo);
        $('#startup_videoPlayer').attr('src', startup_newVideo);
        $('#Startup_videoPlayerModel').modal('show');
    });

    $('#startup_hiddenVideosSection .startup_VideoPlayButton,#startup_upcoming_videos .startup_VideoPlayButton').click(function (e) {
        e.stopPropagation();
        var episode_id = $(this).attr('episode-id');
        var header = {
            "Content-Type": 'application/json'
        };
        $.ajax({
            url: '/episodes/view/' + episode_id,
            headers: header,
            type: "GET",
            contentType: false,
            processData: false,
            success: function (data) {
                // console.log(data);
            }
        });
        var startup_newVideo = $(this).attr('data-video-to-play') + '?autoplay=1';
        //console.log(startup_newVideo);
        $('#startup_videoPlayer').attr('src', startup_newVideo);
        $('#Startup_videoPlayerModel').modal('show');
    });
});

function startup_checkForAllInActive(CurrentClicked)
{
    var totalActivBox = 0;
    $(CurrentClicked).closest('.hiddenVideoList').find('.hiddenVideosMainBox').each(function () {
        if ($(this).attr('data-active-flag') == '1')
        {
            totalActivBox++;
        }
    });
    if (totalActivBox >= 1)
    {
        return false;
    } else
    {
        return true;
    }
}

function startup_setMainVideoWindowValues(currentClickElement)
{
    $(currentClickElement).closest('.startup_mainVideoBlockLevel').find('.startup_videosInfo').each(function (index, element) {
        $(element).css('background-image', 'url(' + $(currentClickElement).attr('data-bg-image') + ')');
    });
}

function subscribe(inputTextboxId) {
    var name = null;
    var location = null;
    var phone = null;
    var email = null;
    var message = null;
    if ('contactus' == inputTextboxId) {
        if ($('.startup_contactInfoFillFormMobile').is(":visible")) {
            var selectorName = '#contactus_first_last_name';
            var selectorLocation = '#contactus_location';
            var selectorPhone = '#contactus_phone';
            var selectorEmail = '#contactus_email';
            var selectorMessage = '#contactus_message';
        } else {
            $('.startup_contactInfoFillFormNewText, .startup_contactInfoFillMobileFormText').css({"background": "unset", "border": "unset", "border-bottom": "1px solid #000"});
            var selectorName = '.startup_contactInfoFillFormInnerFL';
            var selectorLocation = '.startup_contactInfoFillFormInnerCC';
            var selectorPhone = '.startup_contactInfoFillFormInnerPN';
            var selectorEmail = '.startup_contactInfoFillFormInnerEA';
            var selectorMessage = '.startup_contactInfoFillFormInnerQM';
        }
        name = $(selectorName).val();
        location = $(selectorLocation).val();
        phone = $(selectorPhone).val();
        email = $(selectorEmail).val();
        message = $(selectorMessage).val();
        var phoneExp = "/^(\+91[\-\s]?)\d{10}$/";
        if (!name) {
            $(selectorName).css({"background": "#faedec none repeat scroll 0 0", "border": "1px solid #e85445"});
            $('#notifications').empty();
            Notify("Please enter first and last name", null, null, 'danger');
            return false;
        }
        if (!location) {
            $(selectorLocation).css({"background": "#faedec none repeat scroll 0 0", "border": "1px solid #e85445"});
            $('#notifications').empty();
            Notify("Please enter location", null, null, 'danger');
            return false;
        }
        if (phone != '') {
            if (!/^[+]?\d[0-9]+$/.test(phone) || phone.length < 6) {
                $(selectorPhone).css({"background": "#faedec none repeat scroll 0 0", "border": "1px solid #e85445"});
                $('#notifications').empty();
                Notify("Please enter valid phone number", null, null, 'danger');
                return false;
            }
        }
        if (!email || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            $(selectorEmail).css({"background": "#faedec none repeat scroll 0 0", "border": "1px solid #e85445"});
            $('#notifications').empty();
            Notify("Please enter valid email address", null, null, 'danger');
            return false;
        }
        if (!message) {
            $(selectorMessage).css({"background": "#faedec none repeat scroll 0 0", "border": "1px solid #e85445"});
            $('#notifications').empty();
            Notify("Please enter your message", null, null, 'danger');
            return false;
        }
    } else {
        inputTextboxId = ('subscriber' != inputTextboxId) ? inputTextboxId : 'subscriber';
        email = $('.startup_' + inputTextboxId + 'EmailID').val();
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            $('.startup_' + inputTextboxId + 'EmailID').css({"background": "#faedec none repeat scroll 0 0", "border": "1px solid #e85445"});
            $('#notifications').empty();
            Notify("Please enter valid email address", null, null, 'danger');
            return false;
        }
    }
    if (inputTextboxId == 'contactus') {
        $("#contact-button").attr("disabled", "true");
    } else {
        $(".subscribe-button").attr("disabled", "true");
    }
    $.ajax({
        method: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        url: "web-requests",
        data: {request_type: inputTextboxId, name: name, location: location, phone: phone, email: email, message: message},
        success: function (res) {
            // Show Success
            // startup_toastMe('Your request has been Submitted Successfully. \n Thank You.');
            $('#notifications').empty();
            if (inputTextboxId == 'contactus') {
                Notify("We appreciate you reaching out to us. We will get back to you on this shortly!", null, null, 'success');
                $(selectorName).val('');
                $(selectorLocation).val('');
                $(selectorPhone).val('');
                $(selectorEmail).val('');
                $(selectorMessage).val('');
                $("#contact-button").removeAttr("disabled");
            } else {
                Notify("Thanks, your request has been confirmed. You've been added to our list and will hear from us soon.", null, null, 'success');
                $(".subscribe-button").removeAttr("disabled");
                $('.startup_' + inputTextboxId + 'EmailID').val('');
            }

        },
        error: function () {
            if (inputTextboxId == 'contactus') {
                $("#contact-button").removeAttr("disabled");
            } else {
                $(".subscribe-button").removeAttr("disabled");
            }
            // startup_toastMe('Something went wrong!!! Please try again.', 1);
            $('#notifications').empty();
            Notify("Something went wrong!!! Please try again.", null, null, 'danger');
        }
    });
}

var searchEpisode = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
        url: '/episodes/all-episodes/1/searchQuery/%QUERY',
        wildcard: '%QUERY',
        filter: function (data) {
            return $.map(data.details, function (episode) {
                return {
                    type: episode.name,
                };
            });
        }
    }
});

$('#searchQuery').typeahead({
    minLength: 3,
    highlight: true
},
        {
            name: 'lead-by-name',
            display: 'type',
            hint: true,
            highlight: true,
            minLength: 0,
            source: searchEpisode,
            limit: 20
        });

$("#searchQuery").keyup(function () {
    var header = {
        "Content-Type": 'application/json'
    };
    var searchQuery = $.trim($('#searchQuery').val());
    if (searchQuery == '' || searchQuery.length >= 3) {
        if (searchQuery == '' || searchQuery == null) {
            videoNext.searchQuery = null;
        } else {
            videoNext.searchQuery = searchQuery;
        }
        videoNext.topPage = videoNext.recentPage = 1;
        $.ajax({
            url: '/episodes/search-episodes/1/searchQuery/' + videoNext.searchQuery,
            headers: header,
            type: "GET",
            contentType: false,
            processData: false
        }).done(function (data) {
            $('.startup_videosInfoInner').hide();
            $('.startup_closeDetailVideo').hide();
            data.details.categories.forEach(function (category) {
                var htmlString = '';
                data.details.episodes.episodes.forEach(function (episode) {
                    episode.episode_categories.forEach(function (episodeCategory) {
                        if (category.id == episodeCategory.category_id) {
                            htmlString += '<div class="startup_sliderListItem startup_sliderListItemDynamic swiper-slide">\
                                <a href="javascript:void(0)">\
                                    <div class="hiddenVideosMainBoxOuter startup_uploadingVideosBox">\
                                        <div class="hiddenVideosMainBox" episode-id="' + episode.id + '" data-bg-image="/file/' + episode.video_thumb + '" data-current-video="https://www.youtube.com/embed/' + episode.youTubeId + '" data-active-flag="0" data-index-id="1" style="background-image: url(/file/' + episode.video_thumb + ');">\
                                            <div class="startup_smallVideoPlayButton"></div>\
                                            <div class="hiddenVideosMainBoxDetails">\
                                                <div class="hiddenVideosMainBoxDetailsInActive  hiddenVideosMainBoxDetailsText">\
                                                    <div class="hiddenVideosMainBoxDetailsInActiveInner">\
                                                        ' + episode.name + '\
                                                    </div>\
                                                    <div class="hiddenVideosMainBoxDuration">\
                                                        ' + episode.duration + '\
                                                    </div>\
                                                </div>\
                                                <div class="hiddenVideosMainBoxDetailsActive startup_hideMe hiddenVideosMainBoxDetailsText ">\
                                                    <div class="hiddenVideosMainBoxDetailsActiveInner">\
                                                        ' + episode.name + '\
                                                    </div>\
                                                    <div class="selectedBottomArrowOuter">\
                                                        <span class="glyphicon glyphicon-triangle-bottom selectedBottomArrow" aria-hidden="true"></span>\
                                                    </div>\
                                                </div>\
                                            </div>\
                                        </div>\
                                    </div>\
                                </a>\
                            </div>';
                        }
                    });
                });

                if (htmlString == '') {
                    htmlString += '<div class="startup_sliderListItem startup_sliderListItemDynamic startup_sliderListItemEmpty">\
                        No episodes found\
                    </div>';
                }
                $("#" + category.id + " .startup_upcomingVideosList").html(htmlString);
            });
            window.dispatchEvent(new Event('resize'));
        });
    }
});
$(document).ready(function () {

    var mySwiper = new Swiper('.startup_sliderListOuter_0', {
        slidesPerView: 6,
        spaceBetween: 10,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        breakpoints: {
            1200: {
                slidesPerView: 5,
                spaceBetweenSlides: 0
            },
            980: {
                slidesPerView: 4,
                spaceBetweenSlides: 0
            },
            767: {
                slidesPerView: 2,
                spaceBetweenSlides: 0
            },
            567: {
                slidesPerView: 1,
                spaceBetweenSlides: 0
            }
        },
        onInit:function(swiper){
            if(swiper.slides.length > swiper.params.slidesPerView){
                $('.swiper-button-0').show();
            }
            else{
                $('.swiper-button-0').hide();
            }
        }
    });

    var mySwiper = new Swiper('.startup_sliderListOuter_1', {
        slidesPerView: 6,
        spaceBetween: 10,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        breakpoints: {
            1200: {
                slidesPerView: 5,
                spaceBetweenSlides: 0
            },
            980: {
                slidesPerView: 4,
                spaceBetweenSlides: 0
            },
            767: {
                slidesPerView: 2,
                spaceBetweenSlides: 0
            },
            567: {
                slidesPerView: 1,
                spaceBetweenSlides: 0
            }
        },
        onInit:function(swiper){
            if(swiper.slides.length > swiper.params.slidesPerView){
                $('.swiper-button-1').show();
            }
            else{
                $('.swiper-button-1').hide();
            }
        }
    });

    var mySwiper = new Swiper('.startup_sliderListOuter_2', {
        slidesPerView: 6,
        spaceBetween: 10,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        breakpoints: {
            1200: {
                slidesPerView: 5,
                spaceBetweenSlides: 0
            },
            980: {
                slidesPerView: 4,
                spaceBetweenSlides: 0
            },
            767: {
                slidesPerView: 2,
                spaceBetweenSlides: 0
            },
            567: {
                slidesPerView: 1,
                spaceBetweenSlides: 0
            }
        },
        onInit:function(swiper){
            if(swiper.slides.length > swiper.params.slidesPerView){
                $('.swiper-button-2').show();
            }
            else{
                $('.swiper-button-2').hide();
            }
        }
    });

     var mySwiper = new Swiper('.startup_sliderListOuter_liked', {
        slidesPerView: 6,
        spaceBetween: 10,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        breakpoints: {
            1200: {
                slidesPerView: 5,
                spaceBetweenSlides: 0
            },
            980: {
                slidesPerView: 4,
                spaceBetweenSlides: 0
            },
            767: {
                slidesPerView: 2,
                spaceBetweenSlides: 0
            },
            567: {
                slidesPerView: 1,
                spaceBetweenSlides: 0
            }
        },
        onInit:function(swiper){
            if(swiper.slides.length > swiper.params.slidesPerView){
                $('.swiper-button-liked').show();
            }
            else{
                $('.swiper-button-liked').hide();
            }
        }
    });

     var mySwiper = new Swiper('.startup_sliderListOuter_favorite', {
        slidesPerView: 6,
        spaceBetween: 10,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        breakpoints: {
            1200: {
                slidesPerView: 5,
                spaceBetweenSlides: 0
            },
            980: {
                slidesPerView: 4,
                spaceBetweenSlides: 0
            },
            767: {
                slidesPerView: 2,
                spaceBetweenSlides: 0
            },
            567: {
                slidesPerView: 1,
                spaceBetweenSlides: 0
            }
        },
        onInit:function(swiper){
            if(swiper.slides.length > swiper.params.slidesPerView){
                $('.swiper-button-favorite').show();
            }
            else{
                $('.swiper-button-favorite').hide();
            }
        }
    });
    

    $(document.body).on('click', '.startup_sliderListSearchPageResult .startup_videosInfo .comment.shortened .morecontent a', function (e) {
        setTimeout(function () {
            startup_checkHeightOfSearchPageVideoArea();
        }, 200);
    });
    $('.startup_aboutHeadName').each(function () {
        $(this).html($(this).html().replace(' ', '<br />'));
    });
    if ($('.startup_aboutHeadName ').length ==1)
    {
        $(this).find('.startup_aboutSliderContainerSlides').addClass('col-xs-offset-3');
    }
    
    if(startup_isMobile)
    {
         if ($('.startup_aboutHeadName ').length > 1)
        {
            $(this).find('.startup_aboutSliderContainerSlides').addClass('swiper-slide');
            var swiper = new Swiper('.startup_aboutSliderContainer', {
                pagination: '.swiper-pagination',
                effect: 'coverflow',
                autoplay: 2500,
                speed: 1000,
                loop: true,
                grabCursor: true,
                cube: {
                    shadow: true,
                    slideShadows: true,
                    shadowOffset: 20,
                    shadowScale: 0.94
                },
                slidesPerView: '1',
                coverflow: {
                    rotate: 50,
                    stretch: 0,
                    shadow: false,
                    slideShadows: false,
                    shadowOffset: 50,
                    shadowScale: 0.9,
                    depth: 90,
                    modifier: 1
                }
            });
        }
    }
    else
    {
        if ($('.startup_aboutHeadName ').length > 2)
        {
            $(this).find('.startup_aboutSliderContainerSlides').addClass('swiper-slide');
            var swiper = new Swiper('.startup_aboutSliderContainer', {
                pagination: '.swiper-pagination',
                effect: 'coverflow',
                autoplay: 2500,
                speed: 1000,
                loop: true,
                grabCursor: true,
                autoplayDisableOnInteraction:false,
                cube: {
                    shadow: true,
                    slideShadows: true,
                    shadowOffset: 20,
                    shadowScale: 0.94
                },
                slidesPerView: '3',
                coverflow: {
                    rotate: 50,
                    stretch: 0,
                    shadow: false,
                    slideShadows: false,
                    shadowOffset: 50,
                    shadowScale: 0.9,
                    depth: 90,
                    modifier: 1
                }
            });
        }
    }
    
   
    $('.startup_zoomingEffectModel').on('show.bs.modal', function (e) {
        $('.startup_zoomingEffectModel .modal-dialog').attr('class', 'modal-dialog  zoomIn  animated');
    });
    $('.startup_zoomingEffectModel').on('hide.bs.modal', function (e) {
        $('.startup_zoomingEffectModel .modal-dialog').attr('class', 'modal-dialog  zoomOut  animated');
    });
    $("#favorite-videos .startup_sliderListNext").click(function (event) {
        event.stopPropagation();
        var header = {
            "Content-Type": 'application/json'
        };
        $.ajax({
            url: '/episodes/favorite-videos/' + videoNext.favoritePage++,
            headers: header,
            type: "GET",
            contentType: false,
            processData: false,
            success: function (data) {
                var htmlString = '';
                $.each(data.details['episodes'], function (key, value) {
                    htmlString += '<li class="startup_sliderListItem startup_sliderListItemDynamic li_' + value.episode_id + '">\
                        <a href="javascript:void(0)">\
                            <div class="hiddenVideosMainBoxOuter startup_uploadingVideosBox">\
                                <div class="hiddenVideosMainBox" episode-id ="' + value.episode_id + '" data-bg-image="/file/' + value.video_thumb + '" data-current-video="https://www.youtube.com/embed/' + value.youTubeId + '" data-active-flag="0" data-index-id="1" style="background-image: url(/file/' + value.video_thumb + ');">\
                                    <div class="startup_smallVideoPlayButton"></div>\
                                    <div class="hiddenVideosMainBoxDetails">\
                                        <div class="hiddenVideosMainBoxDetailsInActive  hiddenVideosMainBoxDetailsText">\
                                            <div class="hiddenVideosMainBoxDetailsInActiveInner">\
                                                ' + value.name + '\
                                            </div>\
                                        </div>\
                                        <div class="hiddenVideosMainBoxDetailsActive startup_hideMe hiddenVideosMainBoxDetailsText ">\
                                            <div class="hiddenVideosMainBoxDetailsActiveInner">\
                                                ' + value.name + '\
                                            </div>\
                                            <div class="selectedBottomArrowOuter">\
                                                <span class="glyphicon glyphicon-triangle-bottom selectedBottomArrow" aria-hidden="true"></span>\
                                            </div>\
                                        </div>\
                                    </div>\
                                </div>\
                            </div>\
                        </a>\
                    </li>';
                });
                $("#favorite-videos .startup_upcomingVideosList").append(htmlString);
                startup_reInitSlider();
            }
        });
    });
    $("#liked-videos .startup_sliderListNext").click(function (event) {
        event.stopPropagation();
        var header = {
            "Content-Type": 'application/json'
        };
        $.ajax({
            url: '/episodes/liked-videos/' + videoNext.likedPage++,
            headers: header,
            type: "GET",
            contentType: false,
            processData: false,
            success: function (data) {
                var htmlString = '';

                $.each(data.details['episodes'], function (key, value) {
                    htmlString += '<li class="startup_sliderListItem startup_sliderListItemDynamic li_' + value.episode_id + '">\
                        <a href="javascript:void(0)" tabindex="-1">\
                            <div class="hiddenVideosMainBoxOuter startup_uploadingVideosBox">\
                                <div class="hiddenVideosMainBox" episode-id="' + value.episode_id + '" data-bg-image="/file/' + value.video_thumb + '" data-current-video="https://www.youtube.com/embed/' + value.youTubeId + '" data-active-flag="0" data-index-id="1" style="background-image: url(/file/' + value.video_thumb + ');">\
                                    <div class="startup_smallVideoPlayButton"></div>\
                                    <div class="hiddenVideosMainBoxDetails">\
                                        <div class="hiddenVideosMainBoxDetailsInActive  hiddenVideosMainBoxDetailsText">\
                                            <div class="hiddenVideosMainBoxDetailsInActiveInner">' + value.name + '</div>\
                                        </div>\
                                        <div class="hiddenVideosMainBoxDetailsActive startup_hideMe hiddenVideosMainBoxDetailsText">\
                                            <div class="hiddenVideosMainBoxDetailsActiveInner">' + value.name + '</div>\
                                            <div class="selectedBottomArrowOuter">\
                                                <span class="glyphicon glyphicon-triangle-bottom selectedBottomArrow" aria-hidden="true"></span>\
                                            </div>\
                                        </div>\
                                    </div>\
                                </div>\
                            </div>\
                        </a>\
						</li>';
                });
                $("#liked-videos .startup_upcomingVideosList").append(htmlString);
                startup_reInitSlider();
            }
        });
    });
});

var loginForm = $("#loginForm");
loginForm.submit(function (e) {
    var validationStatus = validateEmail(e, 'login_');

    if (validationStatus) {
        e.preventDefault();
        var formData = loginForm.serialize();
        $(".loader").show();
        $.ajax({
            url: '/ajax-login',
            type: 'POST',
            data: formData,
            success: function (data) {
                if (data.success) {
                    location.href = '/home';
                } else {
                    // startup_toastMe(data.message, 1);
                    $('#notifications').empty();
                    Notify(data.message, null, null, 'danger');
                    $(".loader").hide();
                }
            }
        });
    }
});

$('#contactus_first_last_name').keypress(function () {
    var contactus_first_last_name = $.trim($("#contactus_first_last_name").val());
    if (contactus_first_last_name != '') {
        $('#contactus_first_last_name').css({"background": "unset", "border": "1px solid #ccc"});
        $('#notifications').empty();
    }
});
$('#contactus_location').keypress(function () {
    var contactus_location = $.trim($("#contactus_location").val());
    if (contactus_location != '') {
        $('#contactus_location').css({"background": "unset", "border": "1px solid #ccc"});
        $('#notifications').empty();
    }
});
$('#contactus_email').keypress(function () {
    var contactus_email = $.trim($("#contactus_email").val());
    if (contactus_email != '' && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(contactus_email)) {
        $('#contactus_email').css({"background": "unset", "border": "1px solid #ccc"});
        $('#notifications').empty();
    }
});
$('#contactus_message').keypress(function () {
    var contactus_message = $.trim($("#contactus_message").val());
    if (contactus_message != '') {
        $('#contactus_message').css({"background": "unset", "border": "1px solid #ccc"});
        $('#notifications').empty();
    }
});

$('.startup_contactInfoFillFormInnerFL').keypress(function () {
    var startup_contactInfoFillFormInnerFL = $.trim($(".startup_contactInfoFillFormInnerFL").val());
    if (startup_contactInfoFillFormInnerFL != '') {
        $('.startup_contactInfoFillFormInnerFL').css({"background": "unset", "border": "unset", "border-bottom": "1px solid #000"});
        $('#notifications').empty();
    }
});
$('.startup_contactInfoFillFormInnerCC').keypress(function () {
    var startup_contactInfoFillFormInnerCC = $.trim($(".startup_contactInfoFillFormInnerCC").val());
    if (startup_contactInfoFillFormInnerCC != '') {
        $('.startup_contactInfoFillFormInnerCC').css({"background": "unset", "border": "unset", "border-bottom": "1px solid #000"});
        $('#notifications').empty();
    }
});
$('.startup_contactInfoFillFormInnerEA').keypress(function () {
    var startup_contactInfoFillFormInnerEA = $.trim($(".startup_contactInfoFillFormInnerEA").val());
    if (startup_contactInfoFillFormInnerEA != '' && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(startup_contactInfoFillFormInnerEA)) {
        $('.startup_contactInfoFillFormInnerEA').css({"background": "unset", "border": "unset", "border-bottom": "1px solid #000"});
        $('#notifications').empty();
    }

});
$('.startup_contactInfoFillFormInnerQM').keypress(function () {
    var startup_contactInfoFillFormInnerQM = $.trim($(".startup_contactInfoFillFormInnerQM").val());
    if (startup_contactInfoFillFormInnerQM != '') {
        $('.startup_contactInfoFillFormInnerQM').css({"background": "unset", "border": "unset", "border-bottom": "1px solid #000"});
        $('#notifications').empty();
    }
});

$('.startup_joinusEmailID').keypress(function () {
    email = $('.startup_joinusEmailID').val();
    if (email != '' && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        $('.startup_joinusEmailID').css({"background": "unset", "border": "1px solid #ccc"});
        $('#notifications').empty();
    }
});

$('.startup_subscriberEmailID').keypress(function () {
    email = $('.startup_subscriberEmailID').val();
    if (email != '' && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        //$('.startup_subscriberEmailID').css({"background": "unset", "border": "1px solid #ccc"});
        $('#notifications').empty();
    }
});

$('.startup_subscriberFooterEmailID').keypress(function () {
    email = $('.startup_subscriberFooterEmailID').val();
    if (email != '' && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        $('.startup_subscriberFooterEmailID').css({"border": "1px solid #ccc"});
        $('#notifications').empty();
    }
});